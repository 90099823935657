@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.availability {
  $breakpoint: 600px;

  &__session-type {
    margin: 0;
    padding: 0;
    border: none;
    min-width: 0;
  }

  &__radio-list {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$xxs;
    margin-top: spacing.$s;

    @media (min-width: $breakpoint) {
      gap: spacing.$xs;
      margin-top: spacing.$m;
    }
  }

  &__legend {
    margin: 0;
  }

  &__description {
    color: light.$on-surface-primary-alternate;
  }

  &__time {
    display: flex;
    align-items: flex-start;
    gap: spacing.$xs;

    @media (min-width: $breakpoint) {
      gap: spacing.$m;
    }
  }

  &__to {
    display: flex;
    flex-wrap: nowrap;
    align-items: end;
    gap: spacing.$xs;
    flex-grow: 1;
  }

  &__minutes {
    height: 48px; // NOTE: The height of the select element
    display: flex;
    align-items: center;
  }

  &__hr {
    align-self: center;
    width: 13px; //NOTE: This is a hr line with a magic width
    margin-top: 28px; // NOTE: Hard coded to make alignment pretty
  }

  &__input {
    width: 100%;

    @media (min-width: $breakpoint) {
      max-width: 280px;
    }
  }
}
